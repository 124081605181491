import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../../components/Layout/Layout'

export const query = graphql`
  query($slug: String) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      productOrigin
      defaultPrice
      productSummary
      productTastingNotes
      buttonLabel
      mainImage {
        asset {
            fixed(height: 500, width: 400) {
                ...GatsbySanityImageFixed
            }
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout>
    <div className="product__content">
      <div className="container">
        <div className="product__top">
          <div className="product__banner">
            <Image fixed={data?.sanityProduct?.mainImage?.asset?.fixed} />
          </div>
          <div className="product__details">
            <div className="product__header">
              <h2 className="product__sectiontitle">
                { data.sanityProduct.title }
              </h2>
              <h4 className="product__origin">
                { data.sanityProduct.productOrigin }
              </h4>
              <p className="product__price">
                ${ data.sanityProduct.defaultPrice }
              </p>
              <div className="product__summary">
                { data.sanityProduct.productSummary }
              </div>
            </div>
            <div className="product__tasting">
              <h2 className="product__sectiontitle">
                Tastings Notes
              </h2>
              <div className="product__tastingnotes">
                { data.sanityProduct.productTastingNotes }
              </div>
            </div>
            <div className="product__order">
              <Link to="/contact-us/" className="cta">
                Contact To Order
              </Link>
            </div>
          </div>
        </div>
      </div>   
    </div>
  </Layout>
);